import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import { useAsyncState } from '@vueuse/core'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
import swal from 'sweetalert2'

export default function useHelpSupportCategory() {
  // Use toast
  const toast = useToast()

  const refHelpSupportCategoryTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'title', label: 'Category', sortable: true },
    { key: 'desc', label: 'Description', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalCategories = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refHelpSupportCategoryTable.value
      ? refHelpSupportCategoryTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCategories.value,
    }
  })

  const refetchData = () => {
    refHelpSupportCategoryTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  // !! Add Category
  const {
    state: addCategoryState,
    isLoading: isAddCategoryLoading,
    execute: addCategory,
  } = useAsyncState(
    async args => {
      const formData = new FormData()
      formData.append('title', args.data.title)
      formData.append('description', args.data.description)

      const response = await store.dispatch(
        'landing-help-support/addCategory',
        formData,
      )

      if (response.data.code === 200 || response.data.code === true) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Success creating new category',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error creating new category',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }

      return response
    },
    null,
    { immediate: false },
  )

  // !! Edit Category
  const {
    state: editCategoryState,
    isLoading: isEditCategoryLoading,
    execute: editCategory,
  } = useAsyncState(
    async args => {
      const response = await store.dispatch(
        'landing-help-support/editCategory',
        { id: args.slug, data: args.data },
      )

      if (response.data.code === 200 || response.data.code === true) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Success editing question',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error editing question',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }

      return response
    },
    null,
    { immediate: false },
  )

  // !! Delete Category
  const {
    state: deleteCategoryState,
    isLoading: isDeleteCategoryLoading,
    execute: deleteCategory,
  } = useAsyncState(
    async args => {
      await swal
        .fire({
          title: 'Are you sure?',
          text: 'Category will be deleted',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          showClass: {
            popup: 'animate__animated animate__shakeX',
          },
          buttonsStyling: false,
        })
        .then(async result => {
          if (result.value) {
            await store
              .dispatch('landing-help-support/deleteCategory', args._id)
              .then(response => {
                if (response.data.code === 200) {
                  swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Category deleted.',
                    confirmButtonText: 'Ok',
                    timer: 3000,
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                  refetchData()
                } else {
                  swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: response.data.message,
                    confirmButtonText: 'Ok',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                }
              })
          } else if (result.dismiss === swal.DismissReason.cancel) {
            swal.fire({
              title: 'Cancelled',
              text: 'Category not deleted',
              icon: 'error',
              confirmButtonText: 'Ok',
              timer: 3000,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
    null,
    { immediate: false },
  )

  const fetchCategories = (ctx, callback) => {
    store
      .dispatch('landing-help-support/fetchCategories', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const { categories, total_data: total } = response.data.data

        callback(categories)
        totalCategories.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveHelpSupportRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveHelpSupportStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchCategories,
    tableColumns,
    perPage,
    currentPage,
    totalCategories,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refHelpSupportCategoryTable,

    resolveHelpSupportRoleVariant,
    resolveHelpSupportStatusVariant,
    refetchData,

    // Add Category
    addCategory,
    addCategoryState,
    isAddCategoryLoading,

    // Edit Category
    editCategory,
    editCategoryState,
    isEditCategoryLoading,

    // Delete Category
    deleteCategory,
    deleteCategoryState,
    isDeleteCategoryLoading,
  }
}
