<template>
  <!-- Add new help support modal -->
  <b-modal
    id="modal-new-help-support-category"
    :visible="isAddNewHelpSupportCategoryModalShow"
    cancel-variant="outline-secondary"
    size="lg"
    centered
    :title="$t('supportCategory.form.header')"
    hide-footer
    @change="
      val => $emit('update:is-add-new-help-support-category-modal-show', val)
    "
  >
    <template #default="{ hide }">
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetModal"
        >
          <b-row>
            <b-col md="12">
              <validation-provider
                #default="validationContext"
                name="title"
                rules="required"
              >
                <b-form-group
                  :label="$t('supportCategory.form.title')"
                  label-for="title"
                >
                  <b-form-input
                    id="title"
                    v-model="categoryData.title"
                    type="text"
                    :state="getValidationState(validationContext)"
                    :placeholder="$t('supportCategory.form.titlePlaceholder')"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="12">
              <validation-provider
                #default="validationContext"
                name="description"
                rules="required"
              >
                <b-form-group
                  :label="$t('supportCategory.form.description')"
                  label-for="description"
                >
                  <b-form-input
                    id="description"
                    v-model="categoryData.description"
                    type="text"
                    :state="getValidationState(validationContext)"
                    :placeholder="$t('supportCategory.form.descPlaceholder')"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <div class="modal-footer">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('common.cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              :disabled="isAddCategoryLoading"
            >
              <div v-if="isAddCategoryLoading" class="d-flex align-center">
                <b-spinner type="grow" small class="mr-1" />
                Loading...
              </div>
              <span v-else>{{ $t('common.create') }}</span>
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormInvalidFeedback,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import useHelpSupportCategory from './useHelpSupportCategory'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BModal,
    BButton,
    BFormInvalidFeedback,
    BSpinner,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'isAddNewHelpSupportCategoryModalActive',
    event: 'update:is-add-new-help-support-category-modal-show',
  },
  props: {
    isAddNewHelpSupportCategoryModalShow: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },

  setup(props, { emit }) {
    const initialState = {
      title: '',
      description: '',
    }

    const categoryData = ref(JSON.parse(JSON.stringify(initialState)))
    const resetData = () => {
      categoryData.value = JSON.parse(JSON.stringify(initialState))
    }

    const { addCategory, isAddCategoryLoading } = useHelpSupportCategory()

    const resetModal = () => {
      resetData()
      emit('update:is-add-new-help-support-category-modal-show', false)
    }

    const onSubmit = () => {
      addCategory(0, { data: categoryData.value }).then(() => {
        emit('refetch-data')
        resetModal()
      })
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetData,
    )

    return {
      categoryData,
      onSubmit,
      resetModal,
      isAddCategoryLoading,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
