<template>
  <!-- Edit help support modal -->
  <b-modal
    id="modal-edit-help-support-category"
    :visible="isEditHelpSupportCategoryModalShow"
    cancel-variant="outline-secondary"
    size="lg"
    centered
    :title="$t('supportCategory.form.editHeader')"
    hide-footer
    @change="
      val => $emit('update:is-edit-help-support-category-modal-show', val)
    "
  >
    <template #default="{ hide }">
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetModal"
        >
          <b-row>
            <b-col md="12">
              <validation-provider
                #default="validationContext"
                name="title"
                rules="required"
              >
                <b-form-group
                  :label="$t('supportCategory.form.title')"
                  label-for="title"
                >
                  <b-form-input
                    id="title"
                    v-model="data.title"
                    type="text"
                    :state="getValidationState(validationContext)"
                    :placeholder="$t('supportCategory.form.titlePlaceholder')"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="12">
              <validation-provider
                #default="validationContext"
                name="description"
                rules="required"
              >
                <b-form-group
                  :label="$t('supportCategory.form.description')"
                  label-for="description"
                >
                  <b-form-input
                    id="description"
                    v-model="data.description"
                    type="text"
                    :state="getValidationState(validationContext)"
                    :placeholder="$t('supportCategory.form.descPlaceholder')"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <div class="modal-footer">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('common.cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              :disabled="isEditCategoryLoading"
            >
              <div v-if="isEditCategoryLoading" class="d-flex align-center">
                <b-spinner type="grow" small class="mr-1" />
                Loading...
              </div>
              <span v-else>{{ $t('common.save') }}</span>
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormInvalidFeedback,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { ref, watch } from '@vue/composition-api'

import useHelpSupportCategory from './useHelpSupportCategory'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BModal,
    BButton,
    BFormInvalidFeedback,
    BSpinner,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: ['isEditHelpSupportCategoryModalActive', 'editedData'],
    event: 'update:is-edit-help-support-category-modal-show',
  },
  props: {
    isEditHelpSupportCategoryModalShow: {
      type: Boolean,
      required: true,
    },
    editedData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      required,
    }
  },

  setup(props, { emit }) {
    const { editCategory, isEditCategoryLoading } = useHelpSupportCategory()

    const initialState = {
      title: '',
      description: '',
    }

    const data = ref(JSON.parse(JSON.stringify(initialState)))
    const resetData = () => {
      data.value = JSON.parse(JSON.stringify(initialState))
    }

    watch(
      () => props.isEditHelpSupportCategoryModalShow,
      val => {
        if (val) {
          data.value.title = props.editedData.title
          data.value.description = props.editedData.desc
        }

        if (!val) {
          resetData()
        }
      },
    )

    const resetModal = () => {
      // resetData()
      emit('update:is-edit-help-support-category-modal-show', false)
    }

    const updateCategory = () => {
      editCategory(0, { slug: props.editedData._id, data: data.value }).then(
        () => {
          emit('refetch-data')
          emit('update:is-edit-help-support-category-modal-show', false)
        },
      )
    }

    const { refFormObserver, getValidationState } = formValidation(resetData)

    const onSubmit = () => {
      if (
        data.value.title !== props.editedData.title ||
        data.value.description !== props.editedData.desc
      ) {
        updateCategory().then(() => {
          resetModal()
        })
      } else {
        emit('update:is-edit-help-support-category-modal-show', false)
      }
    }

    return {
      data,
      onSubmit,
      resetModal,
      isEditCategoryLoading,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
